import React, { Ref } from "react"
import { useEffect, useState, useCallback, useRef } from "react"
import "./List.css"

import { Cad, useApi, PriceFilter, Sort, ParametricFilter } from "../model/api"

import Item from "./Item"

import InfiniteScroll from "react-infinite-scroller"

type ListProps = {
    isMobile: boolean
    cadList: Cad[] | null
    onCadClicked: (cad: Cad, index: number, newTab: boolean) => void
    onSearchUpdated: (
        keyword: string | null,
        priceFilterType: PriceFilter,
        parametricFilter: ParametricFilter,
        sort: Sort,
    ) => void
    onUploadButtonClicked: () => void
    onProfileButtonClicked: () => void
    loadNextPage: () => Promise<boolean>
    searchValue: string | null
    scrollPositionRef: any
    scrollPos: number
    priceFilter: PriceFilter
    parametricFilter: ParametricFilter
    sort: Sort
}

function List(props: ListProps) {
    const {
        isMobile,
        cadList,
        onCadClicked,
        onSearchUpdated,
        onUploadButtonClicked,
        onProfileButtonClicked,
        searchValue,
        scrollPositionRef,
        scrollPos,
        priceFilter,
        parametricFilter,
        sort,
        loadNextPage,
    } = props
    const [searchText, setSearchText] = useState<string | null>(searchValue)

    const { user, login, logout, isMod } = useApi()

    const callbackcRefs = useRef<any>({})

    const { REACT_APP_API_URL } = process.env

    const [mobileFiltersExpanded, setMobileFiltersExpanded] = useState<boolean>(false)

    const [additionalHeaderOffset, setAdditionalHeaderOffset] = useState<string>("pt-[6.5rem] mb-[-6.5rem]")

    const [moreContent, setMoreContent] = useState<boolean>(true)

    const trigerLoadNextPage = useCallback(async () => {
        setMoreContent(await loadNextPage())
    }, [loadNextPage])

    useEffect(() => {
        setSearchText(searchValue)
    }, [cadList, scrollPos])

    useEffect(() => {
        if (mobileFiltersExpanded) {
            if (window.innerWidth < 700) {
                setAdditionalHeaderOffset("pt-[14.5rem] mb-[-6.5rem]")
            } else {
                setAdditionalHeaderOffset("pt-[10.5rem] mb-[-6.5rem]")
            }
        } else {
            if (window.innerWidth > 670) {
                setAdditionalHeaderOffset("pt-[6.5rem]")
            } else {
                setAdditionalHeaderOffset("pt-[6.5rem] mb-[-6.5rem]")
            }
        }
    }, [isMobile, mobileFiltersExpanded, additionalHeaderOffset])

    const searchUpdated = useCallback(
        (
            keyword: string | null,
            priceFilterType: PriceFilter,
            parametricFilter: ParametricFilter,
            sort: Sort,
        ) => {
            onSearchUpdated(keyword, priceFilterType, parametricFilter, sort)
            setMoreContent(true)
        },
        [cadList],
    )

    const onSearchChange = useCallback(
        (event: any) => {
            const value = event.target.value
            setSearchText(value)
        },
        [cadList],
    )

    const onSearchKeyDown = useCallback(
        (event: any) => {
            if (event.key === "Enter") {
                const value = event.target.value
                if (value.length == 0) {
                    searchUpdated("", priceFilter, parametricFilter, sort)
                } else {
                    searchUpdated(searchText, priceFilter, parametricFilter, sort)
                }
                event.target.blur()
            }
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onSearchBlur = useCallback(
        (event: any) => {
            const value = event.target.value
            if (value.length == 0) {
                searchUpdated("", priceFilter, parametricFilter, sort)
            }
            setSearchText(value)
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onSearchClick = useCallback(
        (event: any) => {
            searchUpdated(searchText, priceFilter, parametricFilter, sort)
        },
        [cadList, searchText, sort, parametricFilter, searchUpdated],
    )

    const onLoginClick = useCallback(
        (event: any) => {
            ;(async () => {
                await login()
                // window.open(REACT_APP_API_URL + "login")
            })()
        },
        [cadList, searchText],
    )

    const onLogoutClick = useCallback(
        (event: any) => {
            ;(async () => {
                await logout()
            })()
        },
        [cadList, searchText],
    )

    const onExpandFilters = useCallback(
        (event: any) => {
            ;(async () => {
                setMobileFiltersExpanded(!mobileFiltersExpanded)
            })()
        },
        [cadList, mobileFiltersExpanded],
    )

    const onChangeParametricFilter = useCallback(
        (newValue: ParametricFilter) => {
            searchUpdated(searchText, priceFilter, newValue, sort)
        },
        [cadList, searchText, priceFilter, sort, parametricFilter, searchUpdated],
    )

    const onChangePriceFilter = useCallback(
        (newValue: PriceFilter) => {
            searchUpdated(searchText, newValue, parametricFilter, sort)
        },
        [cadList, searchText, priceFilter, sort, parametricFilter, searchUpdated],
    )

    const onChangeSortFilter = useCallback(
        (newValue: Sort) => {
            searchUpdated(searchText, priceFilter, parametricFilter, newValue)
        },
        [cadList, searchText, priceFilter, parametricFilter, sort, searchUpdated],
    )

    const priceFilterToDescription = (filter: PriceFilter) => {
        switch (filter) {
            case PriceFilter.ALL:
                return "Paid & Free"
                break
            case PriceFilter.FREE:
                return "Only Free"
                break
            case PriceFilter.PAID:
                return "Only Paid"
                break
        }
    }

    const parametricFilterToDescription = (filter: ParametricFilter) => {
        switch (filter) {
            case ParametricFilter.ALL:
                return "All"
                break
            case ParametricFilter.PARAMETRIC:
                return "Parametric"
                break
        }
    }

    const sortFilterToDescription = (filter: Sort) => {
        switch (filter) {
            case Sort.NEWEST:
                return "Newest"
                break
            case Sort.RATING:
                return "Rating"
                break
            case Sort.POPULAR:
                return "Popular"
                break
            case Sort.CLAIMS:
                return "Claims"
                break
        }
    }

    return (
        <div className="w-screen flex flex-col min-h-screen justify-between">
            {!isMobile && (
                <header className="fixed top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-4 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    <div className="join py-4 mx-4">
                        <div className="dropdown dropdown-hover join-item">
                            <div tabIndex={0} role="button" className="btn btn-outline join-item">
                                {sortFilterToDescription(sort)}
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                            >
                                <li onClick={() => onChangeSortFilter(Sort.POPULAR)}>
                                    <a>{sortFilterToDescription(Sort.POPULAR)}</a>
                                </li>
                                <li onClick={() => onChangeSortFilter(Sort.NEWEST)}>
                                    <a>{sortFilterToDescription(Sort.NEWEST)}</a>
                                </li>
                                <li onClick={() => onChangeSortFilter(Sort.RATING)}>
                                    <a>{sortFilterToDescription(Sort.RATING)}</a>
                                </li>
                                {isMod() && (
                                    <li onClick={() => onChangeSortFilter(Sort.CLAIMS)}>
                                        <a>{sortFilterToDescription(Sort.CLAIMS)}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="dropdown dropdown-hover join-item">
                            <div tabIndex={0} role="button" className="btn btn-outline join-item">
                                {priceFilterToDescription(priceFilter)}
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                            >
                                <li onClick={() => onChangePriceFilter(PriceFilter.ALL)}>
                                    <a>{priceFilterToDescription(PriceFilter.ALL)}</a>
                                </li>
                                <li onClick={() => onChangePriceFilter(PriceFilter.FREE)}>
                                    <a>{priceFilterToDescription(PriceFilter.FREE)}</a>
                                </li>
                                <li onClick={() => onChangePriceFilter(PriceFilter.PAID)}>
                                    <a>{priceFilterToDescription(PriceFilter.PAID)}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown dropdown-hover join-item">
                            <div tabIndex={0} role="button" className="btn btn-outline w-28 join-item">
                                {parametricFilterToDescription(parametricFilter)}
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                            >
                                <li onClick={() => onChangeParametricFilter(ParametricFilter.ALL)}>
                                    <a>{parametricFilterToDescription(ParametricFilter.ALL)}</a>
                                </li>
                                <li onClick={() => onChangeParametricFilter(ParametricFilter.PARAMETRIC)}>
                                    <a>{parametricFilterToDescription(ParametricFilter.PARAMETRIC)}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="join py-4 mx-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={"Enter a title, tag, author or description text."}
                        >
                            <input
                                className="input input-bordered join-item focus:outline-none focus:border-neutral-content border-neutral-content bg-base-100"
                                placeholder="Text"
                                onChange={onSearchChange}
                                onBlur={onSearchBlur}
                                onKeyDown={onSearchKeyDown}
                                value={searchText ? searchText : ""}
                            />
                        </div>
                        <button className="btn btn-accent join-item" onClick={onSearchClick}>
                            Search
                        </button>
                    </div>

                    <div className="join py-4 mx-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={
                                isMobile
                                    ? "Upload not avilable on mobile"
                                    : user
                                      ? "Upload a model"
                                      : "Login and upload a model"
                            }
                        >
                            <button
                                className="btn btn-outline join-item"
                                disabled={!user || isMobile}
                                onClick={onUploadButtonClicked}
                            >
                                Upload
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                        >
                            <button
                                className="btn btn-outline join-item"
                                disabled={!user || isMobile}
                                onClick={onProfileButtonClicked}
                            >
                                Profile
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Logout" : "Login or create your account"}
                        >
                            <button
                                className="btn btn-outline join-item"
                                onClick={user ? onLogoutClick : onLoginClick}
                            >
                                {user ? "Logout" : "Login"}
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {isMobile && !mobileFiltersExpanded && (
                <header className="fixed h-20 top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-2 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                    <div className="py-4 ml-4">
                        <div className="tooltip tooltip-bottom" data-tip="Expand Filters">
                            <button
                                className="btn btn-outline join-item w-16"
                                onClick={onExpandFilters}
                            >
                                {!mobileFiltersExpanded ? "Filters" : "Hide"}
                            </button>
                        </div>
                    </div>
                    <div className="join py-4 mr-4">
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={
                                isMobile
                                    ? "Upload not avilable on mobile"
                                    : user
                                      ? "Upload a model"
                                      : "Login and upload a model"
                            }
                        >
                            <button
                                className="btn btn-outline join-item w-16"
                                disabled={true}
                                onClick={onUploadButtonClicked}
                            >
                                Upload
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                        >
                            <button
                                className="btn btn-outline join-item w-15"
                                disabled={!user}
                                onClick={onProfileButtonClicked}
                            >
                                Profile
                            </button>
                        </div>
                        <div
                            className="tooltip tooltip-bottom"
                            data-tip={user ? "Logout" : "Login or create your account"}
                        >
                            <button
                                className="btn btn-outline join-item w-16"
                                onClick={user ? onLogoutClick : onLoginClick}
                            >
                                {user ? "Logout" : "Login"}
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {mobileFiltersExpanded && isMobile && (
                <header>
                    <div className="fixed h-20 top-0 w-full z-40 bg-base-300 shadow-md flex justify-between flex-wrap gap-2">
                        <div className="py-4 ml-4">
                            <div className="tooltip tooltip-bottom" data-tip="Expand Filters">
                                <button
                                    className="btn btn-outline join-item"
                                    onClick={onExpandFilters}
                                >
                                    {!mobileFiltersExpanded ? "Filters" : "Hide"}
                                </button>
                            </div>
                        </div>
                        <div className="join py-4 mr-4">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Upload a model" : "Login and upload a model"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    disabled={true}
                                    onClick={onUploadButtonClicked}
                                >
                                    Upload
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Manage your profile" : "Login and Manage your profile"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    disabled={!user}
                                    onClick={onProfileButtonClicked}
                                >
                                    Profile
                                </button>
                            </div>
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={user ? "Logout" : "Login or create your account"}
                            >
                                <button
                                    className="btn btn-outline join-item"
                                    onClick={user ? onLogoutClick : onLoginClick}
                                >
                                    {user ? "Logout" : "Login"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-base-300 fixed top-16 mt-2 z-40 shadow-md flex justify-around flex-wrap gap-x-2 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                        <div className="join py-2 mx-4">
                            <div className="dropdown dropdown-hover join-item">
                                <div tabIndex={0} role="button" className="btn btn-outline join-item">
                                    {sortFilterToDescription(sort)}
                                </div>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                                >
                                    <li onClick={() => onChangeSortFilter(Sort.POPULAR)}>
                                        <a>{sortFilterToDescription(Sort.POPULAR)}</a>
                                    </li>
                                    <li onClick={() => onChangeSortFilter(Sort.NEWEST)}>
                                        <a>{sortFilterToDescription(Sort.NEWEST)}</a>
                                    </li>
                                    <li onClick={() => onChangeSortFilter(Sort.RATING)}>
                                        <a>{sortFilterToDescription(Sort.RATING)}</a>
                                    </li>
                                    {isMod() && (
                                        <li onClick={() => onChangeSortFilter(Sort.CLAIMS)}>
                                            <a>{sortFilterToDescription(Sort.CLAIMS)}</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="dropdown dropdown-hover join-item">
                                <div tabIndex={0} role="button" className="btn btn-outline join-item">
                                    {priceFilterToDescription(priceFilter)}
                                </div>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                                >
                                    <li onClick={() => onChangePriceFilter(PriceFilter.ALL)}>
                                        <a>{priceFilterToDescription(PriceFilter.ALL)}</a>
                                    </li>
                                    <li onClick={() => onChangePriceFilter(PriceFilter.FREE)}>
                                        <a>{priceFilterToDescription(PriceFilter.FREE)}</a>
                                    </li>
                                    <li onClick={() => onChangePriceFilter(PriceFilter.PAID)}>
                                        <a>{priceFilterToDescription(PriceFilter.PAID)}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown dropdown-hover join-item">
                                <div tabIndex={0} role="button" className="btn btn-outline w-28 join-item">
                                    {parametricFilterToDescription(parametricFilter)}
                                </div>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content bg-base-300 menu z-[1] rounded-box w-52 p-2 shadow"
                                >
                                    <li onClick={() => onChangeParametricFilter(ParametricFilter.ALL)}>
                                        <a>{parametricFilterToDescription(ParametricFilter.ALL)}</a>
                                    </li>
                                    <li onClick={() => onChangeParametricFilter(ParametricFilter.PARAMETRIC)}>
                                        <a>{parametricFilterToDescription(ParametricFilter.PARAMETRIC)}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="join py-2 mb-3 mx-4">
                            <div
                                className="tooltip tooltip-bottom"
                                data-tip={"Enter a title, tag, author or description text."}
                            >
                                <input
                                    className="input input-bordered join-item focus:outline-none focus:border-neutral-content border-neutral-content bg-base-100"
                                    placeholder="Text"
                                    onChange={onSearchChange}
                                    onBlur={onSearchBlur}
                                    onKeyDown={onSearchKeyDown}
                                    value={searchText ? searchText : ""}
                                />
                            </div>
                            <button className="btn btn-accent join-item" onClick={onSearchClick}>
                                Search
                            </button>
                        </div>
                    </div>
                </header>
            )}

            {cadList && cadList.length == 0 && (
                <div
                    className={
                        "w-full text-center h-full flex flex-col mb-40 justify-around " +
                        additionalHeaderOffset
                    }
                >
                    <div>
                        <p className="text-3xl font-bold mx-4">No search results found</p>
                        <p className="text-lg mx-4">
                            Try changing your search filters or be first and{" "}
                            {!user && <>create an account and Upload your own model.</>}
                            {user && (
                                <a className="link" href="/upload">
                                    upload your own model.
                                </a>
                            )}
                        </p>
                    </div>
                </div>
            )}
            {cadList && cadList.length > 0 && (
                <InfiniteScroll
                    className={`flex justify-around flex-wrap m-0 gap-8 ` + additionalHeaderOffset}
                    pageStart={0}
                    loadMore={trigerLoadNextPage}
                    hasMore={moreContent}
                >
                    {cadList &&
                        cadList.map((cad: Cad, index: number) => {
                            return (
                                <Item
                                    isMobile={isMobile}
                                    key={"item_" + index}
                                    cad={cad}
                                    index={index}
                                    onCadClicked={onCadClicked}
                                ></Item>
                            )
                        })}
                    <div
                        key={"item_placeholder_0"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_1"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_2"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_3"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                    <div
                        key={"item_placeholder_4"}
                        className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                    ></div>
                </InfiniteScroll>
            )}
            <footer className="footer py-4 bg-base-300">
                <aside className="w-full">
                    <div className="flex justify-evenly flex-wrap gap-y-4 w-full">
                        <div className="ml-4 mt-1 text-balance text-center h-full flex items-center">
                            <div className="">Copyright © 2024 - All right reserved by Konrad Faltyn</div>
                        </div>
                        <div className="text-right flex text-center">
                            <a className="link text-base mr-4" href="/terms">
                                Terms and Conditions
                            </a>
                            <a className="link text-base mr-4" href="/privacy">
                                Privacy Policy
                            </a>
                            <a className="link text-base mr-4" href="/cookies">
                                Cookies
                            </a>
                            <a className="link text-base mr-4" href="/refund">
                                Refunds
                            </a>
                        </div>
                        <div className="">
                            <a href={"https://discord.gg/sZVggxuyhM"}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-discord"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </aside>
            </footer>
        </div>
    )
}

export default List
