import React from "react"
import "./Legal.css"

const BuisnessName = "printedmachines.com"
const CompanyName = "Konrad Faltyn"

type TermsProps = {
    isMobile: boolean
}

function Terms(props: TermsProps) {
    const { isMobile } = props
    return (
        <div className={"text-justify mt-20 text-lg mb-16 " + (isMobile ? "mx-8" : "mx-32")}>
            <h1 className="text-4xl font-bold text-center mb-8 mt-[-30px]">{`TERMS AND CONDITIONS`}</h1>
            <p className="my-4">{`Welcome to ${BuisnessName}!`}</p>
            <p className="my-4">{`${BuisnessName} is owned and operated by ${CompanyName}.`}</p>
            <p className="my-4">{`These are the terms and conditions for: ${BuisnessName}`}</p>
            <p className="my-4">{`The following terms and conditions apply to your use of the ${BuisnessName} platform and the services available on the platform. This includes mobile and tablet versions, as well as any other version of ${BuisnessName} accessible via desktop, mobile, tablet, social media or other devices.`}</p>
            <p className="my-4">{`By using the platform, you agree to these terms and conditions and our privacy policy. In these terms and conditions, the words "platform" refers to the ${BuisnessName} website, "we", "us", "our", and "${BuisnessName}", refers to ${BuisnessName}, and "you", and "user", refers to you, the ${BuisnessName} user.`}</p>
            <p className="my-8 font-bold text-xl text-center">{`PLEASE READ THESE CONDITIONS CAREFULLY BEFORE USING THE SERVICES AND FUNCTIONALITIES AVAILABLE ON THE PLATFORM.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`1. ACCEPTANCE OF TERMS`}</p>
            <p className="my-4">{`By using the platform, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use our services and our platform. We may modify this agreement from time to time, and such modification will be effective upon posting on the platform. You agree to be bound by any modifications to these terms and conditions when you use ${BuisnessName} following the posting of such modification; therefore, it is important that you review this agreement regularly.
By using the platform, you represent and warrant that you have the full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are not under any legal incapacity or contractual restriction that would prevent you from entering into this agreement.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`2. NOTIFICATIONS`}</p>
            <p className="my-4">{`By providing your email address to ${BuisnessName}, you agree that we may use your email address to send you notifications about ${BuisnessName}. We may also use your email address to send you notifications and other messages, such as changes to service features, news and special content. If you do not wish to receive these emails, you may opt-out of receiving them by submitting your unsubscribe request through the contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you from receiving notifications and mailings about updates, news or special content.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`3. REGISTRATION`}</p>
            <p className="my-4">{`${BuisnessName} offers users the ability to browse and view all free 3D models without creating an account. However, paid 3D models will be visible in the list, but cannot be fully opened or purchased without a registered account. To access the full functionality of the platform, such as purchasing 3D models and publishing free and paid 3D models, it is necessary to register and open an account.
Users can create an account through our integration with Auth0 by providing a Display Name that will be visible to other users. This information is necessary to personalize the user experience and facilitate interaction within the ${BuisnessName} community.
Users have the right to delete their account by submitting a account deletion request to the suport@printedmachines.com. By doing so, all data provided to ${BuisnessName} will be deleted, including 3D model publications. It is important to note that this action is irreversible, and all data associated with the account will be permanently lost.
Only people over the age of 13 can create an account on ${BuisnessName}. This is to ensure compliance with child protection laws and to provide a safe and appropriate environment for all users.
By registering with ${BuisnessName}, users agree to comply with these terms and conditions, as well as our privacy policies. We are committed to protecting users' personal information and using it only for the purposes described in our terms and conditions and privacy policy. ${BuisnessName} reserves the right to modify these terms and conditions at any time, and users will be notified of any significant changes.
In addition, registered users can access additional features such as managing their posts, participating in the ${BuisnessName} community, and receiving relevant updates and news through the platform. These additional services are designed to enhance the user experience and foster an active and engaged community.
By creating an account, users agree to ${BuisnessName}'s use of their data in accordance with our stated policies and terms, and agree to maintain the confidentiality of their login information to protect their account from unauthorized access.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`4. PUBLICATION OF 3D MODELS`}</p>
            <p className="my-4">{`Registered ${BuisnessName} users can upload and publish free 3D models, which will be available for other users to download at no cost, as well as paid 3D models. When uploading a 3D model, the user must provide the model files and a detailed description.
It is possible to publish 3D models individually, either for free or for a fee. In addition, users have the option to bundle several 3D models into a package and set a price for that package, which cannot be higher than the sum of the price of each individual model. These packages can also be offered free of charge.
The publication of 3D models is subject to moderation and monitoring by ${BuisnessName} to ensure compliance with the publication rules. Models that violate these rules may be removed from the platform. Paid 3D models are reviewed more rigorously to ensure quality and compliance with our policies.
Users have the ability to remove any of their published 3D models at any time, as well as 3D model packages. In addition, they can change the price of a 3D model, as long as it is not part of a paid package.
By uploading and publishing 3D models, users agree to have their content reviewed by our moderation team and guarantee that it does not infringe copyrights, trademarks, or other applicable laws. They also ensure that the descriptions and files provided are accurate and complete.
${BuisnessName} reserves the right to modify these conditions at any time, notifying users of significant changes so that they can adjust their postings as necessary. By using ${BuisnessName}'s publishing services, users agree to abide by these terms and to maintain the integrity and quality of the 3D modeling community.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`5. PUBLISHING RULES`}</p>
            <p className="my-4">{`The following publishing rules apply to all 3D models uploaded to the ${BuisnessName} platform. These rules are designed to ensure the safety, legality and quality of content shared in our community. By uploading 3D models to ${BuisnessName}, users agree to comply with these rules:`}</p>
            <p className="py-2">{`1.	Weapons and Accessories Ban: 3D models depicting real, 3D printed weapons and weapon accessories are not allowed. This includes, but is not limited to, guns, rifles, knives, ammunition, and any components or accessories intended to modify or assemble functioning weapons.`}</p>
            <p className="py-2">{`2.	Illegal Content: It is prohibited to upload any 3D model that violates local, national or international laws. This includes, but is not limited to, models that facilitate illegal activities, promote violence, hatred, discrimination or terrorism.`}</p>
            <p className="py-2">{`3.	Copyright and Intellectual Property: Uploaded 3D models must be original or have the necessary permissions from copyright and intellectual property holders. It is not allowed to upload content that violates copyrights, trademarks, patents, or any other intellectual property rights of third parties.`}</p>
            <p className="py-2">{`4.	Obscene or Inappropriate Content: 3D models containing obscene, pornographic, indecent, or hateful or violent content are not allowed. This includes any graphic depiction that may be considered offensive or inappropriate.`}</p>
            <p className="py-2">{`5.	Accuracy and Description: Users must provide accurate and detailed descriptions of their 3D models. Descriptions must not be misleading or false. Uploaded files must match the description provided.`}</p>
            <p className="py-2">{`6.	Quality and Functionality: Uploaded 3D models must meet a minimum standard of quality and functionality. Incomplete, damaged or non-functioning models will be removed.`}</p>
            <p className="py-2">{`7.	Protection of Personal Data: 3D models containing personally identifiable information of other persons are not allowed to be uploaded without their express consent. This includes, but is not limited to, data such as names, addresses, phone numbers, and any other information that can be used to identify a person.`}</p>
            <p className="py-2">{`8.	Review and Moderation: All 3D models uploaded to ${BuisnessName} are subject to review and moderation. ${BuisnessName} reserves the right to remove any content it deems inappropriate or that does not comply with these posting rules.`}</p>
            <p className="py-2">{`9.	User Responsibility: Users are responsible for the content they upload to ${BuisnessName}. By posting a 3D model, the user warrants that it complies with all applicable laws and regulations and does not violate any of ${BuisnessName}'s posting rules.`}</p>
            <p className="py-2">{`10.	Reports and Penalties: Users may report 3D models that they deem inappropriate or in violation of these posting rules. ${BuisnessName} will investigate such reports and take appropriate action, which may include removal of the content and suspension or termination of the offending user's account.
${BuisnessName} reserves the right to remove any 3D model postings at any time, whether for breach of these posting rules, infringement of third party rights, or for any other reason ${BuisnessName} deems justified to maintain the integrity and security of the platform.
These posting rules are subject to change, and ${BuisnessName} reserves the right to update or modify these rules at any time. Users are encouraged to periodically review these rules to ensure that their content complies with ${BuisnessName}'s current policies. By using the ${BuisnessName} platform, users accept and agree to abide by these posting rules to maintain a safe and respectful community.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`6. COMMISSIONS`}</p>
            <p className="my-4">{`${BuisnessName} will charge sellers a commission for each 3D model or group of models sold through the platform. This commission is automatically deducted from the total amount paid by the buyer at the time of the transaction. The buyer makes the payment through Stripe Connect, and ${BuisnessName}'s commission is subtracted from the amount before transferring the remaining balance to the seller's Stripe Connect account. It is important to note that Stripe may also apply additional fees for the use of its services.
${BuisnessName}'s commission percentage will be clearly disclosed on our website and may be subject to change. ${BuisnessName} is committed to notifying sellers in advance of any changes to the commission rate, allowing users to adjust to the new terms.
In addition to ${BuisnessName}'s commission, Sellers should be aware that Stripe may apply its own payment processing fees, and these fees will also be deducted from the total transaction amount. Stripe's fees vary and sellers should review Stripe's pricing policies for up-to-date information.
The commission deduction process is transparent and is done automatically to ensure smooth and efficient handling of transactions. Once commission is deducted, the remaining value is immediately transferred to the seller's Stripe Connect account, allowing quick access to funds generated from sales.
${BuisnessName} reserves the right to change the commission percentage and any other terms related to fees at any time. We are committed to providing adequate and sufficient advance notice of any significant changes to our commission policies so that sellers can make informed decisions about their continued use of our platform.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`7. PURCHASE OF 3D MODELS`}</p>
            <p className="my-4">{`${BuisnessName} users have the possibility to purchase individual 3D models or groups of 3D models that other users have published on the platform. Transactions for the purchase of these models are made directly between the seller and the buyer through Stripe Connect, ensuring secure and efficient payment management.
Before making a purchase, users are strongly encouraged to carefully review the description of each 3D model or group of 3D models. This description will provide detailed information about the content and features of the model, helping buyers to make an informed decision.
To complete a purchase, the user must select the 3D model or group of 3D models they wish to purchase and proceed with payment through Stripe Connect. Available payment methods include credit and debit cards, as well as other methods supported by Stripe Connect. Once the payment has been successfully processed, the user will be able to download the 3D model and associated files directly from the platform.
It is the buyer's responsibility to ensure that the payment details and model selection are correct before finalizing the transaction. ${BuisnessName} is not responsible for errors in the model selection or payment details provided by the user.
All transactions made on ${BuisnessName} are subject to Stripe Connect policies, including Stripe's terms of use and privacy policies. Users should familiarize themselves with these policies to fully understand the terms under which their payments are processed.
In the event of disputes related to the purchase of 3D models, users may contact ${BuisnessName}'s customer service for assistance. However, since transactions are conducted directly between sellers and buyers through Stripe Connect, any disputes regarding payment or product delivery may also need to be resolved directly through Stripe.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`8. INTELLECTUAL PROPERTY OF THE 3D MODELS`}</p>
            <p className="my-4">
                {`Free 3D printed models published and downloaded through ${BuisnessName} are licensed under `}
                <a
                    className="link"
                    href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Creative Commons - Attribution - Non-Commercial - Share Alike
                </a>
                {` and are protected by copyright and other intellectual property rights. Non-free/paid 3D models that are downloaded or purchased through the ${BuisnessName} platform are protected by copyright and other intellectual property rights. By publishing and selling 3D models on ${BuisnessName}, sellers transfer to buyers the necessary rights to use these models commercially. Sellers, by selling a 3D model on ${BuisnessName}, transfer to the buyer a non-exclusive, worldwide, perpetual license to use, modify and distribute the 3D model in their own commercial projects. This license allows the buyer to incorporate the 3D model into products and services, as well as any digital or print media.
Although buyers receive broad rights for commercial use of the 3D models, this transfer does not include the right to resell, redistribute or sublicense the original 3D models as individual files or as part of a package without substantial modifications. Buyers must add significant value to the 3D model in their own products or services. Sellers warrant that they have the necessary rights to transfer copyright and that their 3D models do not infringe the intellectual property rights of third parties. In the event of any claim related to copyright infringement, Sellers will indemnify and hold ${BuisnessName} and Buyers harmless from any liability.
Buyers must use the 3D models in accordance with intellectual property laws and the terms of the license granted. Any unauthorized use may result in revocation of purchased rights and legal action by the copyright holder. Purchasers may modify the 3D models to suit their specific needs, provided that such modifications do not result in a violation of the terms of the license granted. Significant modifications may include changes to the structure, design or functionality of the 3D model.
${BuisnessName} acts as an intermediary facilitating the purchase and sale of 3D models, and assumes no responsibility for any claims of copyright infringement that may arise. However, ${BuisnessName} is committed to taking prompt and effective action to remove any content that infringes copyright once it has been properly notified. If a user or copyright holder believes that a 3D model posted on ${BuisnessName} infringes his or her intellectual property rights, he or she may submit a notice of infringement to ${BuisnessName}. Upon receipt of a valid notification, ${BuisnessName} will investigate the claim and, if necessary, remove the infringing content from the platform.`}
            </p>
            <p className="mt-8 text-2xl font-semibold">{`9. LIMITATION OF LIABILITY`}</p>
            <p className="my-4">{`${BuisnessName} provides a platform for buying, selling and downloading 3D models, allowing users to interact and transact efficiently and securely. However, ${BuisnessName} does not guarantee the accuracy, completeness or quality of the 3D models available on the platform, nor does it assume any responsibility for them. By using ${BuisnessName}, users agree to the following limitations of liability:`}</p>
            <p className="py-2">{`1.	Quality and Accuracy of 3D Models: ${BuisnessName} is not responsible for the quality, accuracy, legality or suitability of 3D models published on the platform. Users acknowledge that the content is provided by third parties (the sellers) and that ${BuisnessName} has no control over the creation, description or distribution of the 3D models. It is the user's responsibility to review and evaluate the models before making any transaction.`}</p>
            <p className="py-2">{`2.	Transactions and Payments: All transactions made through ${BuisnessName}, including the purchase of 3D models and model packages, are handled through Stripe Connect. ${BuisnessName} is not responsible for any errors, delays or problems related to payment processing by Stripe. Users should direct any payment-related complaints directly to Stripe.`}</p>
            <p className="py-2">{`3.	Copyright Infringement: ${BuisnessName} is not responsible for any copyright or intellectual property infringement that may result from the publication or sale of 3D models on the platform. Sellers warrant that they have the necessary rights to sell their models and that these do not infringe the rights of third parties. In the event of a dispute, Sellers undertake to indemnify and hold ${BuisnessName} harmless against any claim.`}</p>
            <p className="py-2">{`4.	Interactions between Users: ${BuisnessName} is not responsible for interactions between users, including disputes that may arise from transactions, communications or any other type of interaction. Any disputes must be resolved directly between the parties involved. ${BuisnessName} reserves the right to intervene at its discretion, but is not obligated to mediate disputes.`}</p>
            <p className="py-2">{`5.	Availability of the Service: ${BuisnessName} does not guarantee the continuous and uninterrupted availability of the platform. There may be times when the service is temporarily unavailable due to maintenance, upgrades, technical failures or causes beyond ${BuisnessName}'s control. ${BuisnessName} shall not be liable for any loss of data, business interruption or any damages resulting from the unavailability of the platform.`}</p>
            <p className="py-2">{`6.	Limitation of Damages: To the maximum extent permitted by law, ${BuisnessName} shall not be liable for any indirect, incidental, special, consequential or punitive damages, including, but not limited to, loss of profits, data, use, goodwill or other intangible losses, resulting from (i) the use or inability to use the platform; (ii) any conduct or content of any third party on the platform; (iii) any content obtained from the platform; and (iv) unauthorized access, use or alteration of the streams or user content, whether based on warranty, agreement, tort (including negligence) or any other legal theory, whether or not ${BuisnessName} has been advised of the possibility of such damages.`}</p>
            <p className="py-2">{`7.	Maximum Liability: ${BuisnessName}'s total liability to users for any claim arising out of or related to the use of the platform shall not exceed the total amount paid by the user to ${BuisnessName} in the 12 months prior to the event giving rise to the liability.`}</p>
            <p className="py-2">{`8.	Modification of Terms: ${BuisnessName} reserves the right to modify these terms and conditions at any time. Significant changes will be notified to users, and continued use of the platform following the posting of changes will constitute acceptance of the changes. Users are encouraged to periodically review these terms to be informed of any updates.
By using the ${BuisnessName} platform, users accept these limitations of liability and acknowledge that the same are reasonable given the characteristics of the service provided. ${BuisnessName} is committed to making every effort to provide a safe and satisfactory experience, but cannot guarantee specific results or assume responsibility for factors beyond its control.
`}</p>
            <p className="mt-8 text-2xl font-semibold">{`10. COPYRIGHT`}</p>
            <p className="my-4">{`All the content available on the platform, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by ${BuisnessName} or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on ${BuisnessName} are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without ${BuisnessName} prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize ${BuisnessName} or any part of the material for any purpose other than its intended purposes is strictly prohibited.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`11. COPYRIGHT INFRINGEMENT`}</p>
            <p className="my-4">{`${BuisnessName} will respond to all inquiries, complaints and claims relating to alleged infringement by breach or violation of the provisions contained in Polish and international copyright and intellectual property laws and regulations. ${BuisnessName} respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on the website infringes your copyright or other intellectual property rights, please submit your request through our contact information, with the following information:  `}</p>
            <p className="py-2">{`•	Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.`}</p>
            <p className="py-2">{`•	A statement specifically identifying the location of the infringing material. `}</p>
            <p className="py-2">{`•	Your name, address, telephone number and email address.`}</p>
            <p className="py-2">{`•	A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.`}</p>
            <p className="py-2">{`•	A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.`}</p>
            <p className="py-2">{`•	An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.
`}</p>
            <p className="mt-8 text-2xl font-semibold">{`12. PROHIBITED ACTIVITIES`}</p>
            <p className="my-4">{`The following activities are prohibited:`}</p>
            <p className="py-2">{`•	Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.`}</p>
            <p className="py-2">{`•	Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.`}</p>
            <p className="py-2">{`•	Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.`}</p>
            <p className="py-2">{`•	Deep-link to any portion of the services for any purpose without our express written permission.`}</p>
            <p className="py-2">{`•	"Frame", "mirror" or otherwise incorporate any part of the platform into any other platform or service without our prior written authorization.`}</p>
            <p className="py-2">{`•	Attempt to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any software program used by ${BuisnessName}.`}</p>
            <p className="py-2">{`•	Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict use or copying of any content. 
`}</p>
            <p className="mt-8 text-2xl font-semibold">{`13. DISCLAIMER OF WARRANTIES`}</p>
            <p className="my-4">{`Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse ${BuisnessName} for any loss or damage caused as a result.
${BuisnessName} shall not be liable for any amount for failure to perform any obligation under this Agreement if such failure is due to the occurrence of any unforeseen event beyond its reasonable control, including, without limitation, Internet outages, communications outages, fire, flood, or any uncontrollable act of nature.
Subject as aforesaid, to the maximum extent permitted by law, ${BuisnessName} excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to ${BuisnessName} and ${BuisnessName} shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:`}</p>
            <p className="py-2">{`•	Any incorrect or inaccurate information on the platform.`}</p>
            <p className="py-2">{`•	The infringement by any person of any Intellectual property rights of any third party caused by their use of the platform.`}</p>
            <p className="py-2">{`•	Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.`}</p>
            <p className="py-2">{`•	Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.`}</p>
            <p className="py-2">{`•	Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from ${BuisnessName}.`}</p>
            <p className="py-2">{`•	All representations, warranties, conditions and other terms which but for this notice would have effect.
`}</p>
            <p className="mt-8 text-2xl font-semibold">{`14. ELECTRONIC COMMUNICATIONS`}</p>
            <p className="my-4">{`${BuisnessName} will not accept any responsibility for failed, partial or garbled computer transmissions, for any breakdown, failure, connection or availability of computer, telephone, network, electronic or Internet hardware or software, for Internet accessibility or availability or traffic congestion or for any unauthorized human acts, including any errors or mistakes.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`15. INDEMNIFICATION`}</p>
            <p className="my-4">{`You agree to defend and indemnify ${BuisnessName} from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:`}</p>
            <p className="py-2">{`•	Your breach of this agreement or the documents referenced herein.`}</p>
            <p className="py-2">{`•	Your violation of any law or the rights of a third party.`}</p>
            <p className="py-2">{`•	Your use of the ${BuisnessName} platform.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`16. CHANGES`}</p>
            <p className="my-4">{`We may change the platform and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect. `}</p>
            <p className="mt-8 text-2xl font-semibold">{`17. INTEGRATION CLAUSE`}</p>
            <p className="my-4">{`This agreement together with the privacy policy and any other legal notices published by ${BuisnessName}, shall constitute the entire agreement between you and ${BuisnessName} concerning and governs your use of the platform.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`18. SEVERABILITY`}</p>
            <p className="my-4">{`If any section of these terms is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired. Our failure to enforce or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision in the future.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`19. FORCE MAJEURE`}</p>
            <p className="my-4">{`${BuisnessName} shall not be liable for any failure due to causes beyond its reasonable control, including but not limited to acts of civil authorities, acts of military authorities, riots, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances. `}</p>
            <p className="mt-8 text-2xl font-semibold">{`20. TERMINATION`}</p>
            <p className="my-4">{`Both the user and ${BuisnessName} acknowledge that failure to comply with these terms and conditions may result in termination of the agreement between both parties.
In case of non-compliance by the user: If the user fails to comply with any of the terms set forth in these terms and conditions, ${BuisnessName} reserves the right to unilaterally terminate the user's access to and use of the platform. ${BuisnessName} may take this action without prior notice, and the user will automatically lose all rights to access and use the platform. Furthermore, ${BuisnessName} shall not be liable for any loss of data or information associated with the user's account.
In case of non-compliance by ${BuisnessName}: If ${BuisnessName} fails to comply with its obligations under the agreed terms and conditions, the user shall be entitled to terminate the agreement and stop using the platform. The user shall notify ${BuisnessName} in writing of the breach and allow a reasonable time for ${BuisnessName} to remedy the situation. If ${BuisnessName} does not remedy the breach within the set period, the user may proceed with the termination of the agreement. 
Termination of the agreement shall not release either party from any obligations and liabilities that have arisen prior to the date of termination.  
Continued use of the platform after termination of the agreement by either party shall imply acceptance of the conditions set forth in the terms and conditions in effect at that time.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`21. DISPUTES`}</p>
            <p className="my-4">{`You acknowledge and agree that any dispute, claim or controversy arising out of or relating to these terms and conditions, whether for breach, termination, enforcement, interpretation or validity, as well as any aspect of the use of the platform, shall preferably be resolved by binding arbitration between you and ${BuisnessName}. However, the right of both parties to bring individual claims before a court of competent jurisdiction if deemed necessary remains.
In the event of any dispute arising in connection with the use of the platform or breach of these terms and conditions, both parties agree to seek a solution through an arbitration process. This process will be carried out before a recognized and respected arbitration entity, selected by mutual agreement of both parties and following the applicable commercial arbitration rules.
In addition, the user agrees, to the extent permitted by applicable law, not to initiate, join or participate in class action lawsuits associated with any claim, dispute or controversy that may arise in connection with the use of the platform. This commitment seeks to promote a more agile and personalized resolution of any conflict, favoring arbitration and individual legal actions over collective proceedings.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`22. GOVERNING LAW AND JURISDICTION`}</p>
            <p className="my-4">{`Although the platform is available internationally, these terms and conditions shall be governed by and construed in accordance with the laws of Poland and the European Union. Any dispute relating to these terms shall be subject to the exclusive jurisdiction of the courts of Poland. This applies unless binding arbitration is agreed to in the applicable section. `}</p>
            <p className="mt-8 text-2xl font-semibold">{`23. FINAL PROVISIONS`}</p>
            <p className="my-4">{`The use of our platform and the services offered by ${BuisnessName} is conditioned upon your acceptance of and compliance with all of the terms and conditions set forth. This authorization to use our services does not extend to jurisdictions where these provisions are not respected or enforced.
Our commitment to the enforcement of these terms is strictly governed by applicable laws and legal process. Importantly, these terms do not restrict our ability to comply with legal or governmental requirements, including, but not limited to, those related to law enforcement and the use of our platform. Information provided or collected in connection with the use of the platform will be subject to these requirements.
In the event that any provision of these terms is held invalid, illegal or unenforceable by a court or competent authority, such decision shall not affect the validity or enforceability of the remaining provisions. The failure or delay in enforcing any of these terms by us at any time shall not constitute a waiver of our rights to enforce such provision, or any other provision, in the future.
We reserve all rights not expressly granted herein, while maintaining the protection of and respect for our intellectual property rights and prerogatives at all times.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`24. CONTACT INFORMATION`}</p>
            <p className="my-4">{`If you have questions or concerns about these terms, please contact us using the contact information below:
${CompanyName} - ${BuisnessName}.`}</p>
            <p className="my-4">{`(Contact at: support@printedmachines.com)`}</p>
        </div>
    )
}

export default Terms
