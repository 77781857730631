import React from "react"
import "./Legal.css"

const BuisnessName = "printedmachines.com"
const CompanyName = "Konrad Faltyn"

type PrivacyProps = {
    isMobile: boolean
}

function Privacy(props: PrivacyProps) {
    const { isMobile } = props
    return (
        <div className={"text-justify mt-20 text-lg mb-16 " + (isMobile ? "mx-8" : "mx-32")}>
            <h1 className="text-4xl font-bold text-center mb-8 mt-[-30px]">{`PRIVACY POLICY`}</h1>
            <p className="my-4">{`Welcome to ${BuisnessName}!`}</p>
            <p className="my-4">{`${BuisnessName} is owned and operated by ${CompanyName}.`}</p>

            <p className="my-4">{`${BuisnessName} values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our databases and, if necessary, with whom we share it.
By using the platform and services, you are accepting the practices described in this privacy policy. Your use of the platform is also subject to our terms and conditions. In this privacy policy, the words "platform" refers to the ${BuisnessName} website, "we", "us", "our", and "${BuisnessName}", refers to ${BuisnessName}, and "you", and "user", refers to you, the ${BuisnessName} user.
This privacy policy may change from time to time. Your continued use of the platform and services after we make changes to this privacy policy will be deemed acceptance of those changes, so please check this policy periodically for updates. This privacy policy has been developed and is maintained in accordance with all applicable national and international privacy and data protection laws and regulations and specifically, the General Data Protection Regulation (GDPR - European regulations).`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">1. GENERAL INFORMATION</p>
            <p className="my-4">{`
The personal data of users that are collected and processed through:`}</p>
            <p className="my-4">{`•	${BuisnessName}`}</p>
            <p className="my-4">Will be under the responsibility and in charge of:</p>
            <p className="my-4">• {CompanyName}.</p>
            <p className="my-4">{`•	(Insert contact information, email, phone, etc.)`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">2. HOW WE OBTAIN YOUR CONSENT</p>
            <p className="my-4">{`
When you browse the website, you register as a user and create a profile, use the functionalities available on the website, upload a 3D model or groups of models to the platform, purchase a 3D model or groups of models through the platform, and contact us through our contact information and contact form, you consent to our collection, storage and use of your information under the terms set out in this privacy policy.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">3. TYPES OF INFORMATION COLLECTED</p>
            <p className="my-4">{`
The information we collect from our users helps us to continually improve the user experience on the platform. These are the types of information we collect:
Information you provide to us. You provide information when you browse the website, register as a user and create a profile, use the functionalities available on the website, upload a 3D model or groups of models to the platform, purchase a 3D model or groups of models through the platform and contact us through our contact information and contact form. As a result of those actions, you may provide us with the following information:`}</p>
            <p className="my-4">{`•	Full name`}</p>
            <p className="my-4">{`•	Display Name`}</p>
            <p className="my-4">{`•	Email`}</p>
            <p className="my-4 font-bold">{`If the user is also a seller, additionally we collect:`}</p>
            <p className="my-4">{`•	Address`}</p>
            <p className="my-4">{`•	Company name / buisness name / name`}</p>
            <p className="my-4">{`•	Tax information for invoicing`}</p>
            <p className="font-bold mt-8">{`Usage Data:`}</p>
            <p className="my-4">{`As you browse our website, certain usage data is automatically collected. These include technical information such as the IP address of your device, the type and version of your browser, the specific pages you visit on our website, the date and time of your access, the amount of time you spend on those pages, and other diagnostically relevant data.
In the case of access via mobile devices, we also collect information specific to these devices. This may include, but is not limited to, the type of mobile device used, its unique identifier, the IP address of the device, the mobile operating system and the type of mobile browser, along with other identifiers and diagnostic data.
In addition, we collect information that your browser automatically provides each time you visit our website or access our website using a mobile device.`}</p>
            <p className="font-bold mt-8">{`Registration and Login:`}</p>
            <p>{`${BuisnessName} uses Auth0 to manage the registration and login of our users, ensuring secure and efficient authentication. When registering on our platform, personal data such as name, email address and, in some cases, profile picture is collected. This information is essential for account creation and management, allowing us to provide a personalized and secure user experience.
${BuisnessName} does not store passwords on our servers, as Auth0 handles this process using advanced security standards to protect sensitive data. In addition, Auth0 may collect additional information, such as IP address, to enhance security and prevent unauthorized access.
Users have the right to access, update or delete their personal information through their account settings or by contacting our support team.`}</p>
            <p className="my-4">{`See Auth0's privacy policy here:`}</p>
            <p className="my-4">
                •{" "}
                <a className="link" href="https://www.okta.com/privacy-policy/">
                    https://www.okta.com/privacy-policy/
                </a>
            </p>

            <p className="font-bold mt-8">{`User Content:`}</p>
            <p className="my-4">{`${BuisnessName} collects, processes and stores user content, which includes content and information provided through the use of the functionalities available on the platform, including uploads of 3D models to the platform and information provided through the user's profile, solely for the provision and improvement of the functionalities available on the platform. We are committed to maintaining the confidentiality and security of this content and information, and will not share it with unauthorized third parties, unless required by law or for the operation of the platform.`}</p>
            <p className="font-bold mt-8">{`Payment Data:`}</p>
            <p>{`Payment data refers to information related to your credit or debit card, such as card number, expiration date, security code, etc. Your payment data will be processed by the payment processors available on this platform (Stripe), which will process and store your data securely and for the sole purpose of processing user transactions for the purchase and sale of 3D models published on the platform. ${CompanyName} reserves the right to engage any available payment processor.
`}</p>
            <p className="my-4">{`View Stripe's privacy policy here:`}</p>
            <p className="my-4">
                •{" "}
                <a className="link" href="https://stripe.com/privacy">
                    https://stripe.com/privacy
                </a>
            </p>
            <p className="font-bold mt-8">{`Contact information:`}</p>
            <p className="my-4">{`We may access some personal information about the user, such as name and email address, when the user or any third party communicates with us through our contact information. Personal information provided through our contact information is not stored on any ${BuisnessName} server and will be stored on the respective server of our email service.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">4. HOW LONG WE KEEP YOUR DATA</p>
            <p className="my-4">{`
Personal data provided by users through the platform will be retained for the time necessary to provide the functionalities available on the platform, fulfill the legitimate purposes described in section 5 of this policy or until the user closes the user account or requests the deletion of their data. ${BuisnessName} may retain personal data for a longer period provided that the user has consented to such processing, as long as such consent is not withdrawn. In addition, ${BuisnessName} may be obliged to retain personal data for a longer period provided that this is required for compliance with a legal obligation or by order of an authority. Once the retention period has expired, the personal data will be deleted. Therefore, the right of access, the right of deletion, the right of rectification and the right to data portability cannot be asserted after the retention period has expired.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">
                5. HOW WE USE YOUR INFORMATION (LEGITIMATE PURPOSES)
            </p>
            <p className="my-4">{`In general, we use the information we collect primarily to provide, maintain, protect and improve our platform and to provide the functionalities available on the platform appropriately. We use personal information collected through our platform as described below:`}</p>
            <p className="my-2">{"•	Facilitate the registration and creation of user profiles."}</p>
            <p className="my-2">{"•	Provide the functionalities available on the platform."}</p>
            <p className="my-2">{"•	Facilitate the upload and publication of 3D models on the platform."}</p>
            <p className="my-2">{"•	Facilitate the purchase of 3D models published on the platform."}</p>
            <p className="my-2">{"•	Process payments for the purchase of 3D models."}</p>
            <p className="my-2">{`•	Deduct the commission corresponding to ${BuisnessName} from each transaction.`}</p>
            <p className="my-2">{"•	Understand and improve your experience using our platform."}</p>
            <p className="my-2">{"•	Respond to your comments or questions through our support team."}</p>
            <p className="my-2">
                {
                    "•	Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages."
                }
            </p>
            <p className="my-2">{`•	Send you notifications and relevant information about ${BuisnessName}.`}</p>
            <p className="my-2">{`•	${BuisnessName} marketing purposes.`}</p>
            <p className="my-2">
                {"•	Process user requests related to the exercise of their rights over their personal data."}
            </p>
            <p className="my-2">
                {
                    "•	Link or combine your information with other data we obtain from third parties to help us understand your needs and provide you with better service."
                }
            </p>
            <p className="my-2">
                {"•	Protect, investigate and deter fraudulent, unauthorized or illegal activities."}
            </p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">6. HOW WE SHARE INFORMATION</p>
            <p className="my-4">{`
Information about our users is an important part of our business, and we are not in the business of selling it to third parties. We share customer information only as described below.`}</p>
            <p className="font-bold mt-8">{`Third-party suppliers.`}</p>
            <p className="my-4">{`We use third party services to perform certain functions on our platform. Some of these functions and services include: website creation and hosting, payment processing (Stripe), sending emails, and tracking and analyzing data on the platform.`}</p>
            <p className="my-4">{`These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.`}</p>
            <p className="mt-4 font-bold">Auth0:</p>
            <p className="">{`We use Auth0 to manage the registration and login of our users. In doing so, some personal information, such as name and email, is transferred to Auth0 to ensure secure authentication. In addition, Auth0 may collect additional information, such as IP address, to enhance security. Lifty ensures that these transfers comply with applicable data protection laws and does not share this information with third parties without the user's consent, except for legal reasons. By using Lifty, users agree to this privacy policy.`}</p>
            <p className="my-4">{`See Auth0's privacy policy here:`}</p>
            <p className="my-4">
                •{" "}
                <a className="link" href="https://www.okta.com/privacy-policy/">
                    https://www.okta.com/privacy-policy/
                </a>
            </p>
            <p className="mt-4 font-bold">Stripe:</p>
            <p>{`We use Stripe, a third party payment processor to securely handle transactions between users, related to the purchase and sale of 3D models, and the deduction of our commission. When a transaction is made, the user's financial information, such as payment details, is transferred to Stripe to complete the transaction and apply the corresponding commission. Lifty does not directly store this financial information. Stripe ensures data security and complies with data protection regulations. By using Lifty, users consent to this transfer of data to facilitate transactions and commission management.`}</p>
            <p className="my-4">{`View Stripe's privacy policy here:`}</p>
            <p className="my-4">
                •{" "}
                <a className="link" href="https://stripe.com/privacy">
                    https://stripe.com/privacy
                </a>
            </p>
            <p className="mt-4 font-bold">E-mail communications.</p>
            <p>{`By providing us with your email address, you consent and agree that we may send relevant content, notifications and information to your email address. Therefore, your email address may be shared with third party bulk email services for the sole and exclusive purpose of sending you relevant communications. If you wish to stop receiving communications from ${BuisnessName}, you may unsubscribe at any time by using the "unsubscribe" option available in the same emails or by sending your request through our contact information.`}</p>
            <p className="mt-4 font-bold">Business Transfers.</p>
            <p className="">{`In the event ${BuisnessName} creates, merges with, or is acquired by another entity, your information will likely be transferred. ${BuisnessName} will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy.`}</p>
            <p className="mt-4 font-bold">{`Protection of ${BuisnessName} and Others.`}</p>
            <p className="">{`We release personal information when we believe release is appropriate to comply with the law, enforce or apply our terms and conditions and other agreements, or protect the rights, property, or safety of ${BuisnessName}, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.`}</p>
            <p className="mt-4 font-bold">{`Anonymous Information.`}</p>
            <p>{`${BuisnessName} uses anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">7. DATA BREACH NOTIFICATIONS</p>
            <p className="my-4">{`
In the event of a security breach that compromises the confidentiality of the personal data of our users, ${BuisnessName} undertakes to notify those affected in a timely manner. This notification will be made through the means of contact that have been provided by the user on our platform. We will take all reasonable measures to protect the information and remedy any situation that compromises the security of your data.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">8. INTERNATIONAL DATA TRANSFER</p>
            <p className="my-4">{`
By using our platform, you agree that your personal data may be transferred and processed outside your country of origin, where data protection laws may differ. This is because the data may be stored on servers located outside your country. ${BuisnessName} is committed to taking the necessary steps to ensure that your data is treated in accordance with applicable privacy standards and is adequately protected during any international transfer.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">9. PROTECTION OF YOUR INFORMATION</p>
            <p className="my-4">{`
We grant access to your personal information only to those outside persons or services that have a legitimate need to know it and in accordance with our privacy policy. We adhere to industry-recognized security standards to protect your personal information, both during transmission and in storage. However, it is important to note that no method of transmission over the Internet or electronic storage is foolproof and 100% secure. Therefore, while we at ${BuisnessName} strive to implement commercially viable data protection methods, we cannot ensure absolute security of your personal information. We undertake not to sell, distribute or transfer your personal data to unauthorized third parties, unless we have your explicit consent or are required by law to do so.`}</p>

            <p className="mt-8 text-2xl font-semibold text-center mb-4">10. RIGHTS</p>
            <p className="my-4">{`
Users who provide information through our platform, as data subjects, have the right to access, rectify, download or delete their information, as well as to restrict and oppose certain processing of their information. While some of these rights apply generally, others only apply in certain limited circumstances. These rights are described below:`}</p>
            <p className="my-2">{`•	Access and portability: to access and know what information is stored on our servers, you can send us your request through our contact information.`}</p>
            <p className="my-2">{`•	Rectification, restriction, limitation and deletion: You may also rectify, restrict, limit or delete much of your information.`}</p>
            <p className="my-2">{`•	Right to be informed: Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is kept and whether it is shared with third parties.`}</p>
            <p className="my-2">{`•	Object: Where we process your data based on our legitimate interests, as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have legitimate grounds to continue processing it or where necessary for legal reasons.`}</p>
            <p className="my-2">{`•	Withdraw consent: Where you have previously given your consent, for example to allow us to process and store your personal information, you have the right to withdraw your consent to the processing and storage of your information at any time. For example, you can withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a lawful basis for doing so or if your withdrawal of consent was limited to certain processing activities.`}</p>
            <p className="my-2">{`•	Complaint: If you wish to lodge a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users may exercise all of these rights by contacting us via the contact information or contact page.`}</p>
            <p className="my-2">{`•	Rights related to automated decision-making, including profiling: Users may request that we provide them with a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.`}</p>
            <p className="my-4">{`Users or holders of personal data provided through the platform may exercise these rights over their personal data at any time and without limitation by sending their request through our contact information. The request to exercise their rights will be attended and answered within a maximum period of 10 working days.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">{`11. PROTECTION OF CHILDREN'S ONLINE PRIVACY`}</p>
            <p className="my-4">{`
We comply with national and international data protection regulations regarding the protection of children's personal data. We do not collect any information from children under the age of 13. If we become aware that a child under the age of 13 has provided us with personal information, we will take immediate steps to delete such information.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">12. THIRD PARTIES</p>
            <p className="my-4">{`
Except as expressly included in this privacy policy, this document addresses only the use and disclosure of information ${BuisnessName} collects from you. If you disclose your information to others, whether other users or ${BuisnessName} vendors, different rules may apply to their use or disclosure of the information you disclose to them. ${BuisnessName} does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. ${BuisnessName} is not responsible for the privacy or security practices of another Internet platform, including those linked to or from the ${BuisnessName} platform. Please review the privacy policies of third-party websites or services that you access through the ${BuisnessName} platform.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">13. CHANGES TO PRIVACY POLICY</p>
            <p className="my-4">{`
We reserve the right to change our privacy policy at any time. Changes will be promptly notified to our users and posted on the website. Your continued use of our website and services following these changes constitutes acceptance of the changes.`}</p>
            <p className="mt-8 text-2xl font-semibold text-center mb-4">14. CONTACT INFORMATION</p>
            <p className="my-4">{`
If you have questions or concerns about this privacy policy and the treatment and security of your data, please contact us through by using the following contact information:`}</p>
            <p>{`${CompanyName} - ${BuisnessName}.`}</p>
            <p>{`Contact at: support@printedmachines.com`}</p>
        </div>
    )
}

export default Privacy
