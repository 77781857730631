import React, { useCallback, useEffect, useState, useContext, useRef } from "react"
import "./Details.css"
import { SceneEntry } from "../model/api"

type GIFStlProps = {
    onFinishLoading: () => void
    onStartLoading: () => void
    onError: (err: Error) => void
    captureReady: (recording: Blob) => void
    baseUrl: string
    bodies: string[]
    scene: SceneEntry
    capture: boolean
    color: any
    blobBody?: Blob
    description: string
    title: string
    tags: string[]
    author: string
}

function GIFStl(props: GIFStlProps) {
    const {
        onFinishLoading,
        onStartLoading,
        onError,
        baseUrl,
        bodies,
        scene,
        capture,
        captureReady,
        color,
        description,
        title,
        tags,
        author,
    } = props

    const [stlObject, setStlObject] = useState<any | null>(null)

    const stl = useRef<any>(null)
    const previousUrl = useRef<string>(baseUrl)
    const isSTLLoading = useRef<boolean>(false)

    const callbackcRefs = useRef<any>({})

    useEffect(() => {
        if (!stlObject) {
            return
        }

        var models = []

        models = bodies.map((body, index) => {
            return {
                id: index,
                filename: baseUrl + body,
                name: body,
                // blob:
            }
        })

        if (stlObject.get_models_count() == models.length && previousUrl.current == baseUrl) {
            return
        }
        previousUrl.current = baseUrl

        onStartLoading()
        stlObject.hide()
        stlObject.clean()
        stlObject.add_models(
            models.map((model, index) => {
                return {
                    filename: model.filename,
                    name: model.name,
                    id: index,
                }
            }),
        )
    }, [stlObject, bodies, baseUrl])

    useEffect(() => {
        if (!stlObject) {
            return
        }
        if (capture) {
            stlObject.startCapture()
        }
    }, [capture, stlObject])

    useEffect(() => {
        if (!stlObject) {
            return
        }
        const stlObj = stl.current

        stlObj.get_models().forEach((model: any, index: number) => {
            var placement = scene[model.name]
            if (!placement) {
                placement = {
                    x: 0,
                    y: 0,
                    z: 0,
                    yaw: 0,
                    roll: 0,
                    pitch: 0,
                }
            }
            stlObj.set_position(index, placement.x, placement.y, placement.z)
            stlObj.set_color(index, color)
        })
        onFinishLoading()
    }, [stlObject, color])

    useEffect(() => {
        if (stl.current == null) {
            return
        }

        const replaceListener = (key: string, newCallback: any) => {
            if (callbackcRefs.current[key]) {
                document.removeEventListener(key, callbackcRefs.current[key])
            }
            callbackcRefs.current[key] = newCallback
            document.addEventListener(key, newCallback)
        }

        replaceListener("all_loaded_event", () => {
            const stlObj = stl.current
            stlObj.get_models().forEach((model: any, index: number) => {
                var placement = scene[model.name]
                if (!placement) {
                    placement = {
                        x: 0,
                        y: 0,
                        z: 0,
                        yaw: 0,
                        roll: 0,
                        pitch: 0,
                    }
                }
                stlObj.set_position(index, placement.x, placement.y, placement.z)
                stlObj.set_color(index, color)

                stlObj.show()
            })
            onFinishLoading()
        })
    }, [stlObject, scene, color])

    useEffect(() => {
        if (isSTLLoading.current) {
            return
        } else {
            isSTLLoading.current = true
        }

        ;(async () => {
            if (stlObject) {
                return
            }
            const dependencyPromises = [
                new Promise((resolve, reject) => {
                    if ((window as any).StlViewer) {
                        resolve("")
                    } else {
                        setTimeout(() => {
                            if ((window as any).StlViewer) {
                                resolve("")
                            }
                        }, 100)
                    }
                }),
            ]

            await Promise.all([dependencyPromises])

            var models = []

            models = bodies.map((body, index) => {
                return {
                    id: index,
                    filename: baseUrl + body,
                    name: body,
                }
            })

            const initParams = {
                models: models,
                camerax: -45,
                cameray: 45,
                auto_rotate: true,
                all_loaded_callback: () => {
                    const readyEvent = new Event("all_loaded_event")
                    document.dispatchEvent(readyEvent)
                },
                ready_callback: (newStl: any) => {
                    newStl.hide()
                    setStlObject(newStl)
                },
                load_error_callback: (error: any) => {
                    onError(error)
                },
                recording_ready: (recording: Blob) => {
                    captureReady(recording)
                },
            }

            const doc = document.getElementById("stl_cont_gif")
            if (doc) {
                doc.innerHTML = ""
            }
            stl.current = (window as any).StlViewer(doc, initParams)
        })()
    }, [])

    return (
        <div>
            <div className="w-[400px] text-center">Move/zoom out/in to setup the thumbnail gif.</div>
            <div
                className={
                    "card card-compact w-96 bg-base-300 shadow-xl flex-none m-3 " +
                    (capture && "pointer-events-none")
                }
            >
                <figure className="h-[15rem]">
                    <div id="stl_cont_gif" className={`h-[400px] w-[500px] bg-base-200`}></div>
                </figure>
                <div className="card-body">
                    <h2 className="card-title text-2xl mb-2">{title}</h2>
                    <p className="text-left mb-3">{description}</p>
                    <div className="card-actions justify-end">
                        {tags.map((tag: string, index: number) => {
                            return (
                                <div key={"item_tag_" + index} className="badge badge-outline">
                                    {tag}
                                </div>
                            )
                        })}
                    </div>
                    <div className="container text-right mt-2">
                        <p className="object-right-top">
                            {author} - {new Date().toLocaleDateString()}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GIFStl
