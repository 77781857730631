import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./app/App"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ApiContextProvider } from "./model/api"
import { KindeProvider } from "@kinde-oss/kinde-auth-react"
import { Adsense } from "@ctrl/react-adsense"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const {
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_REDIRECT_URI,
    REACT_APP_AUTH_LOGOUT_URI,
    REACT_APP_AUTH_AUDIENCE,
} = process.env

root.render(
    <div data-theme="dark">
        <React.StrictMode>
            <KindeProvider
                clientId={REACT_APP_AUTH_CLIENT_ID || ""}
                domain={REACT_APP_AUTH_DOMAIN || ""}
                redirectUri={REACT_APP_AUTH_REDIRECT_URI || ""}
                logoutUri={REACT_APP_AUTH_LOGOUT_URI || ""}
                audience={REACT_APP_AUTH_AUDIENCE}
            >
                <ApiContextProvider>
                    <HelmetProvider>
                        <Helmet>
                            <script async src="./../jszip/jszip.js" type="text/javascript"></script>
                            <script
                                async
                                src="./../stl_viewer/stl_viewer.min.js"
                                type="text/javascript"
                            ></script>
                            <script
                                async
                                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3940574523898158"
                                crossOrigin="anonymous"
                            ></script>
                            <title>PrintedMachines</title>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            />
                            <meta charSet="utf-8" />
                            <meta
                                name="description"
                                content="Explore the ultimate 3D printing models marketplace, offering a vast collection of STL files for instant download. Whether you're into toys, tools, or home décor, find high-quality designs ready for printing. Start your next project with the perfect 3D model today! Free Web tutorials"
                            />
                            <meta name="keywords" content="3dprint,marketplace,3dmodels,models,print" />
                        </Helmet>
                        <App />
                    </HelmetProvider>
                </ApiContextProvider>
            </KindeProvider>
        </React.StrictMode>
    </div>,
)
