import React, { Ref, useContext } from "react"
import { useEffect, useState, useCallback, useRef } from "react"
import "./Upload.css"

import { Details, DetailsMode } from "../details/Details"
import { Cad, UploadCadParameters, FileType, ApiContext } from "../model/api"

enum FileTypeUpload {
    FREECAD = ".FCStd",
    OBJ = ".obj",
    STL = ".stl",
}

enum FileValidState {
    Empty = "empty",
    Valid = "valid",
    InvalidExtension = "invalid",
    InvalidSize = "size",
}

function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function fileStateToInputStyle(state: FileValidState) {
    if (state == FileValidState.Empty) {
        return ""
    } else if (state == FileValidState.InvalidExtension || state == FileValidState.InvalidSize) {
        return "file-input-error"
    } else if (state == FileValidState.Valid) {
        return "file-input-accent"
    }
}

const MAX_FILE_SIZE = 20

type UploadProps = {
    isMobile: boolean
    backButtonPressed: () => void
    showSuccessMessage: (message: string) => void
    showErrorMessage: (message: string) => void
}
function Upload(props: UploadProps) {
    const { backButtonPressed, isMobile, showErrorMessage, showSuccessMessage } = props

    const { prerenderCad, prerenderStl, objToStl, upload } = useContext(ApiContext)

    const [fileType, setFileType] = useState<FileTypeUpload | null>(null)
    const [fileName, setFileName] = useState<string | undefined>(undefined)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [fileToUpload, setFileToUpload] = useState<any | undefined>(undefined)
    const [fileValidState, setFileValidState] = useState<FileValidState>(FileValidState.Empty)
    const [cad, setCad] = useState<Cad | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [cadFileType, setCadFileType] = useState<FileType | undefined>(undefined)

    const preuploadCad = useCallback(
        (file: File) => {
            ;(async () => {
                var cad

                switch (fileType) {
                    case FileTypeUpload.FREECAD:
                        setIsLoading(true)
                        cad = await prerenderCad(file)
                        setIsLoading(false)
                        setFileToUpload(file)
                        setCadFileType(FileType.FREECAD)
                        break
                    case FileTypeUpload.OBJ:
                        setIsLoading(true)
                        const stlBlob = await objToStl(file)
                        setFileToUpload(new Blob([stlBlob]))
                        cad = await prerenderStl(new Blob([stlBlob]))
                        setIsLoading(false)
                        setCadFileType(FileType.STL)
                        break
                    case FileTypeUpload.STL:
                        setIsLoading(true)
                        cad = await prerenderStl(file)
                        setIsLoading(false)
                        setFileToUpload(file)
                        setCadFileType(FileType.STL)
                        break
                }
                setCad(cad)
            })()
        },
        [prerenderCad, prerenderStl, objToStl, fileType, file, cadFileType],
    )

    const handleFileChange = useCallback(
        (event: any) => {
            if (event.target.files) {
                const targetFile = event.target.files[0] as File
                setFile(targetFile)
                setFileName(targetFile.name)

                const extension = "." + targetFile.name.split(".").pop()
                const size = targetFile.size / 1024 / 1024

                if (
                    [
                        FileTypeUpload.FREECAD.toLowerCase(),
                        FileTypeUpload.OBJ.toLowerCase(),
                        FileTypeUpload.STL.toLowerCase(),
                    ].includes(extension.toLowerCase()) &&
                    size <= MAX_FILE_SIZE
                ) {
                    setFileType(extension as FileTypeUpload)
                    setFileValidState(FileValidState.Valid)
                } else if (size > MAX_FILE_SIZE) {
                    setFileType(null)
                    setFileValidState(FileValidState.InvalidSize)
                } else {
                    setFileType(null)
                    setFileValidState(FileValidState.InvalidExtension)
                }
            } else {
                setFile(undefined)
                setFileName(undefined)
            }
        },
        [file],
    )

    useEffect(() => {
        if (fileValidState == FileValidState.Valid && file) {
            preuploadCad(file)
        }
    }, [fileType, fileValidState, file])

    const onBackButtonClicked = useCallback(() => {
        setCad(undefined)
        setFile(undefined)
        setFileValidState(FileValidState.Empty)
        setCadFileType(undefined)
    }, [])

    const onFinishLoading = useCallback(() => {
        setIsLoading(false)
    }, [cad])

    const onStartLoading = useCallback(() => {
        setIsLoading(true)
    }, [cad])

    const handleUpload = useCallback(
        (file: File, properties: UploadCadParameters, capturedImage: Blob): Promise<Cad | undefined> => {
            return new Promise((resolve, reject) => {
                ;(async () => {
                    onStartLoading()
                    const result = await upload(fileToUpload, capturedImage, properties)
                    onFinishLoading()
                    resolve(result)
                })()
            })
        },
        [cadFileType, fileToUpload],
    )

    return (
        <div className="">
            {isLoading && (
                <div className="flex w-screen h-screen bg-transparent z-100 absolute top-0 left-0">
                    <div className="m-auto bg-transparent">
                        <div className="bg-base-200 py-3 px-4 rounded-lg">
                            <span className="mt-1 loading loading-bars loading-lg"></span>
                        </div>
                    </div>
                </div>
            )}
            {!cad && (
                <div>
                    <div className="flex py-2 mb-2 fixed left-5 top-2 z-20">
                        <button className="btn btn-outline" onClick={backButtonPressed}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 ml-[-4px] z-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={1.5}
                            >
                                <path
                                    d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                    fillRule="nonzero"
                                />
                            </svg>
                            <div className="ml-0">Back</div>
                        </button>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full h-screen">
                        <label className="form-control w-full max-w-xs mt-5">
                            <div className="label">
                                <span className="label-text">Pick a .stl, .obj or .FCStd file</span>
                            </div>
                            <input
                                type="file"
                                className={
                                    "file-input file-input-bordered w-full max-w-xs " +
                                    fileStateToInputStyle(fileValidState)
                                }
                                id="file"
                                onChange={handleFileChange}
                            />
                            <div className="label">
                                <span className="label-text-alt">
                                    {file && formatBytes(file.size) + " / " + MAX_FILE_SIZE + "Mb"}
                                    {!file && "0 kB / " + MAX_FILE_SIZE + "Mb"}
                                </span>
                                <span className="label-text-alt">
                                    {fileValidState == FileValidState.InvalidExtension && "Invalid File type"}
                                    {fileValidState == FileValidState.InvalidSize &&
                                        "File to large. Max: " + MAX_FILE_SIZE + "Mb"}
                                </span>
                            </div>
                        </label>
                        <div>
                            <h2 className="text-lg font-bold mb-2 mt-10">
                                How to setup parametric Freecad files (2min video):{" "}
                            </h2>
                            <iframe
                                width="784"
                                height="441"
                                src="https://www.youtube.com/embed/kBLYM8wFewY?si=tTWnbWd-CoMXXUm0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
            {cad && cadFileType && (
                <Details
                    isMobile={isMobile}
                    cad={cad}
                    onBackButtonClicked={onBackButtonClicked}
                    mode={DetailsMode.UPLOAD}
                    file={file}
                    makeUpload={handleUpload}
                    type={cadFileType}
                    reload={() => {}}
                    showErrorMessage={showErrorMessage}
                    showSuccessMessage={showSuccessMessage}
                ></Details>
            )}
        </div>
    )
}

export default Upload
