import React, { act, Ref, useContext } from "react"
import { useEffect, useState, useCallback, useRef } from "react"
import "./Profile.css"

import {
    Cad,
    User,
    useApi,
    Grouping,
    Transaction,
    TransactionType,
    TransactionStatus,
    Profile,
} from "../model/api"

import Item from "../list/Item"

type ProfileProps = {
    isMobile: boolean
    profile: Profile
    onBackButtonClicked: () => void
    onCadClicked: (cad: Cad, index: number, newTab: boolean) => void
}

function PublicUser(props: ProfileProps) {
    const { profile, onBackButtonClicked, onCadClicked, isMobile } = props

    return (
        <div className="">
            <header className="fixed top-0 w-full z-40 bg-base-300 flex items-center justify-center shadow-md h-20 drop-shadow-[0_15px_15px_rgba(0,0,0,0.2)]">
                <div className="flex py-2 pl-4 z-20 absolute left-4">
                    {isMobile && (
                        <div className="flex">
                            <button
                                className="btn btn-outline h-12 ml-[-10px] mr-4"
                                onClick={onBackButtonClicked}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 ml-[0px] z-0 w-[24px]"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={1.5}
                                >
                                    <path
                                        d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                        fillRule="nonzero"
                                    />
                                </svg>
                            </button>
                        </div>
                    )}
                    {!isMobile && (
                        <button className="btn btn-outline my-2" onClick={onBackButtonClicked}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 ml-[-4px] z-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={1.5}
                            >
                                <path
                                    d="m10.978 14.999v3.251c0 .412-.335.75-.752.75-.188 0-.375-.071-.518-.206-1.775-1.685-4.945-4.692-6.396-6.069-.2-.189-.312-.452-.312-.725 0-.274.112-.536.312-.725 1.451-1.377 4.621-4.385 6.396-6.068.143-.136.33-.207.518-.207.417 0 .752.337.752.75v3.251h9.02c.531 0 1.002.47 1.002 1v3.998c0 .53-.471 1-1.002 1z"
                                    fillRule="nonzero"
                                />
                            </svg>
                            <div className="ml-0">Back</div>
                        </button>
                    )}
                </div>
                {!isMobile && <div className="text-3xl">{profile.displayName}</div>}
            </header>
            <div className="pt-24 w-full mb-4">
                {isMobile && (
                    <div className="text-3xl mt-[5px] w-full text-center">{profile.displayName}</div>
                )}
                {profile.description && profile.description.length > 0 && (
                    <div
                        className={
                            "bg-base-200 mt-4 py-4 rounded-2xl mx-4 text-left " +
                            (!isMobile ? "px-8" : "px-4")
                        }
                    >
                        <p className="text-lg font-bold mb-2">Profile Description:</p>
                        <p className={"text-lg break-all text-wrap mb-4 " + (!isMobile ? "mx-4" : "")}>
                            {profile.description}
                        </p>

                        {profile.youtubeUrl && (
                            <a className="link text-lg" href={profile.youtubeUrl}>
                                {profile.youtubeUrl.includes("youtube") ? "Youtube Channel" : "Webpage"}
                            </a>
                        )}
                    </div>
                )}

                <div className="flex justify-around flex-wrap pt-8 m-0 gap-8">
                    {profile.groupings.map((grouping: Grouping, index: number) => {
                        if (isMobile) {
                            return (
                                <div key={grouping.id} className="bg-base-200 h-full my-4 w-full">
                                    <div className="">
                                        <div className="ml-4 mt-4 text-3xl font-medium text-left">
                                            {grouping.rest ? "Models" : grouping.title}
                                        </div>
                                        <div className="ml-4 text-base font-medium text-left mt-2 mx-2">
                                            {grouping.description}
                                        </div>
                                        {grouping.url && (
                                            <a
                                                className="link text-lg ml-4"
                                                href={grouping.url}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {grouping.url}
                                            </a>
                                        )}
                                    </div>
                                    <div className="flex justify-around flex-wrap m-0 pt-4 gap-8">
                                        {grouping.cads.map((cad: Cad, index: number) => {
                                            return (
                                                <Item
                                                    isMobile={isMobile}
                                                    key={grouping.id + "_" + index}
                                                    cad={cad}
                                                    index={index}
                                                    onCadClicked={onCadClicked}
                                                ></Item>
                                            )
                                        })}
                                        <div
                                            key={"item_placeholder_0"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <details
                                    key={grouping.id}
                                    className="collapse bg-base-200 collapse-arrow mx-4 w-min-96 w-full h-full my-4"
                                    open={true}
                                >
                                    <summary className="collapse-title">
                                        <div className="text-3xl font-medium text-left">
                                            {grouping.rest ? "Models" : grouping.title}
                                        </div>
                                        <div className="text-base font-medium text-left mt-2">
                                            {grouping.description}
                                        </div>
                                        {grouping.url && (
                                            <a className="link text-lg mt-2" href={grouping.url}>
                                                {grouping.url}
                                            </a>
                                        )}
                                    </summary>
                                    <div className="collapse-content w-full flex justify-around flex-wrap m-0 gap-8">
                                        {grouping.cads.map((cad: Cad, index: number) => {
                                            return (
                                                <Item
                                                    isMobile={isMobile}
                                                    key={grouping.id + "_" + index}
                                                    cad={cad}
                                                    index={index}
                                                    onCadClicked={onCadClicked}
                                                ></Item>
                                            )
                                        })}
                                        <div
                                            key={"item_placeholder_0"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                        <div
                                            key={"item_placeholder_1"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                        <div
                                            key={"item_placeholder_2"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                        <div
                                            key={"item_placeholder_3"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                        <div
                                            key={"item_placeholder_4"}
                                            className="card w-96 bg-base-100 shadow-xl flex-none m-0 h-0"
                                        ></div>
                                    </div>
                                </details>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default PublicUser
