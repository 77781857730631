import React from "react"
import "./Legal.css"

const BuisnessName = "printedmachines.com"
const CompanyName = "Konrad Faltyn"

type RefundProps = {
    isMobile: boolean
}

function Refund(props: RefundProps) {
    const { isMobile } = props
    return (
        <div className={"text-justify mt-20 text-lg mb-16 " + (isMobile ? "mx-8" : "mx-32")}>
            <h1 className="text-4xl font-bold text-center mb-8 mt-[-30px]">{`REFUND POLICY`}</h1>
            <p className="my-4">{`Welcome to ${BuisnessName}!`}</p>
            <p className="my-4">{`${BuisnessName} is owned and operated by ${CompanyName}.`}</p>
            <p className="my-4">{`At ${BuisnessName}, we strive to provide our users with the best possible experience when purchasing and downloading digital 3D models. However, due to the nature of the digital products published on our platform, we do not offer returns or refunds. The following are the terms and conditions related to our return and refund policy:`}</p>
            <p className="mt-8 text-2xl font-semibold">1. NON-REFUNDABLE DIGITAL PRODUCTS</p>
            <p className="my-4">{`All 3D models purchased and downloaded through the ${BuisnessName} platform are digital products. Once the user has initiated the download of the product, it is non-refundable under any circumstances. Users should carefully review product descriptions and details before making a purchase.`}</p>
            <p className="mt-8 text-2xl font-semibold">2. PURCHASE CONFIRMATION</p>
            <p className="my-4">{`Before completing a transaction, users are asked to confirm their intent to purchase. This confirmation includes explicit acceptance that, by initiating the download of the product, they waive their right to return or refund.`}</p>
            <p className="mt-8 text-2xl font-semibold">3. PRODUCT REVIEW</p>
            <p className="my-4">{`We strongly encourage users to take advantage of the information available on each 3D model, including descriptions, images and any other details provided by the seller, to make an informed decision before making a purchase. ${BuisnessName} is not responsible for errors in product selection due to insufficient review by the user.`}</p>
            <p className="mt-8 text-2xl font-semibold">4. TECHNICAL PROBLEMS AND SUPPORT</p>
            <p className="my-4">{`In the event of technical problems with downloading or accessing a digital product, users should contact ${BuisnessName} technical support for assistance. Our team is committed to helping resolve any technical issues that may arise during the download process. However, resolution of technical issues does not imply eligibility for a refund.`}</p>
            <p className="mt-8 text-2xl font-semibold">5. EXCEPTIONS</p>
            <p className="my-4">{`In exceptional cases, such as when a product is determined to be defective or does not correspond to the description provided, ${BuisnessName} may consider offering a credit on the platform or allowing the download of an alternative product. Any such request will be evaluated on a case-by-case basis and will be subject to ${BuisnessName}'s discretion.`}</p>
            <p className="mt-8 text-2xl font-semibold">{`6. SELLER'S LIABILITY`}</p>
            <p className="my-4">{`Sellers on ${BuisnessName} are responsible for ensuring that the products they post are of high quality and comply with the descriptions provided. In case of discrepancies or claims related to product quality, ${BuisnessName} reserves the right to take action against the seller, including removal of the product and possible suspension of the seller's account.`}</p>
            <p className="mt-8 text-2xl font-semibold">7. POLICY UPDATES</p>
            <p className="my-4">{`${BuisnessName} reserves the right to update this returns and refunds policy at any time. Users will be notified of any significant changes, and are encouraged to periodically review these terms to ensure they are aware of current policies.`}</p>
            <p className="mt-8 text-2xl font-semibold">8. CONTACT US</p>
            <p className="my-4">{`If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through by using the contact information below:`}</p>
            <p className="my-4">
                {CompanyName} - {BuisnessName}.
            </p>
            <p className="my-4">{`Contact at: support@printedmachines.com`}</p>
        </div>
    )
}

export default Refund
